import { Injectable, inject } from '@angular/core';
import { WebsocketService } from 'websocket';
import { NotificationService } from 'notification';
import { LoadingService } from 'loading';
import { ApiService } from 'api';
import { Staff } from 'staff-preview';
import { Router } from '@angular/router';
import { PinState } from '../../pin/store/pin.state';
import { ProfileState } from './profile.state';
import { StaffType } from 'models/staff/types/staff-types';

@Injectable({
  providedIn: 'root',
})
export class ProfileResolver {
  public readonly websocketService = inject(WebsocketService);
  public readonly notificationService = inject(NotificationService);
  public readonly loadingService = inject(LoadingService);
  public readonly apiService = inject(ApiService);
  public readonly router = inject(Router);

  public readonly pinState = inject(PinState);
  public readonly state = inject(ProfileState);

  public backToPin() {
    this.router.navigate(['/pin']);
    this.pinState.staff().data.set(undefined);
  }

  public hasPin(redirectTo: string) {
    if (!this.pinState.staff().data()) {
      this.router.navigateByUrl(redirectTo);

      return false;
    }

    return true;
  }

  public getStaff() {
    const model = this.pinState.model().model;

    this.state.staff.set({
      data: this.pinState.staff().data() as Staff,
      type: model === 'User' ? StaffType.Stambridge : StaffType.Contractor,
    });
  }
}
