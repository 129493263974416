import {
  Injectable,
  WritableSignal,
  inject,
  signal,
} from '@angular/core';
import { PinState } from '../../pin/store/pin.state';
import { ProfileStaff } from './profile.type';
import { AuthService } from 'auth';

@Injectable({
  providedIn: 'root',
})
export class ProfileState {
  public readonly pinState = inject(PinState);
  public readonly authService = inject(AuthService);
  public readonly staff = signal<ProfileStaff | undefined>(undefined);

  get infoStaff() {
    return this.staff as WritableSignal<ProfileStaff>;
  }
}
